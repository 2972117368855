import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/create-user',
    name: 'create-user',
    component: () => import('../views/CreateUser.vue')
  },
  {
    path: '/create-account',
    name: 'create-account',
    component: () => import('../views/CreateAccount.vue')
  },
  {
    path: '/edit-account',
    name: 'EditAccount',
    component: () => import('../views/EditAccount.vue')
  },
  {
    path: '/petition',
    name: 'petition',
    component: () => import('../views/Petition.vue')
  },
  {
    path: '/select-case',
    name: 'select-case',
    component: () => import('../views/SelectCase.vue')
  },
  {
    path: '/case-sex-discrimination',
    name: 'case-sex-discrimination',
    component: () => import('../views/CaseSexDiscriminationViolence.vue')
  },
  {
    path: '/case-medium-prejudice',
    name: 'case-medium-prejudice',
    component: () => import('../views/CaseMediumPrejudice.vue')
  },
  {
    path: '/case-conscription',
    name: 'case-conscription',
    component: () => import('../views/CaseConscription.vue')
  },
  {
    path: '/case-dress',
    name: 'case-dress',
    component: () => import('../views/CaseDress.vue')
  },
  {
    path: '/case-employment',
    name: 'case-employment',
    component: () => import('../views/CaseEmployment.vue')
  },
  {
    path: '/publication',
    name: 'publication',
    component: () => import('../views/Publication.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/admin-petition',
    name: 'admin-petition',
    component: () => import('../views/AdminPetition.vue')
  },{
    path: '/detail',
    name: 'detail',
    component: () => import('../views/Detail.vue')
  },{
    path: '/pop-up',
    name: 'pop-up',
    component: () => import('../views/Popup.vue')
  },
  {
    path: '/thank-popup',
    name: 'thank-popup',
    component: () => import('../views/ThankPopup.vue')
  },
  {
    path: '/callback',
    name: 'callback',
    component: () => import('../views/Callback.vue')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/ResetPassword.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  document.title = "TGA";
  next();
});

export default router
