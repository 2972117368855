<template>
  <v-app>
    <nav class="navbar"> 
        <img class="logo" alt="tga" src="./assets/logo_tga.png" 
          contain
        />
        <a class="toggle-button mt-3" @click="showMenu()">
          <hr class="bar">
          <hr class="bar">
          <hr class="bar">
        </a>
        <div class="navbar-links hidden-sm-and-down" >
          <ul class="nav-items" v-if="is_login">
            <li><a class="menu" style="color:#6D4A97;" href="/"><strong>หน้าแรก</strong></a></li>
            <li v-if="is_admin"><a class="menu" style="color:#4C549F" href="/admin-petition"><strong>แจ้งปัญหา</strong></a></li>
            <li v-else><a class="menu" style="color:#4C549F" href="/petition"><strong>แจ้งปัญหา</strong></a></li>
            <li><a class="menu" style="color:#539750" href="/publication"><strong>สื่อรณรงค์</strong></a></li>
            <li><a class="menu" style="color:#FFC107" href="/contact"><strong>ติดต่อเรา</strong></a></li>
          </ul>
          <ul class="nav-items" v-else>
            <li><a class="menu" style="color:#6D4A97;" href="/"><strong>หน้าแรก</strong></a></li>
            <li><a class="menu" style="color:#4C549F" href="/login"><strong>แจ้งปัญหา</strong></a></li>
            <li><a class="menu" style="color:#539750" href="/login"><strong>สื่อรณรงค์</strong></a></li>
            <li><a class="menu" style="color:#FFC107" href="/contact"><strong>ติดต่อเรา</strong></a></li>
          </ul>
        </div>
        <div class="avartar hidden-sm-and-down" v-if="isLogin">
          <v-btn icon x-large @click="hidden = !hidden">
            <v-avatar size="70">
              <v-img
                v-if="have_image"
                :src="image_profile"
                alt=""
              />
              <v-img
                v-else
                src="./assets/profile_image.jpg"
                alt=""
              />
            </v-avatar>
          </v-btn>
        </div>
        <v-btn 
          v-else
          class="login-btn hidden-sm-and-down"
          rounded dark large depressed
          style="background-image: linear-gradient(90deg, #ED8D1D 39%, #FBD76C 80%);"
          width="120px"
          to="/login"
        >
          <strong>เข้าสู่ระบบ</strong>
        </v-btn>      
    </nav>
    <v-main>
      <div class="wrapper">
        <div class="profile-menu" v-if="hidden">
          <v-navigation-drawer
            v-model="hidden"
            class="mr-6"
            absolute temporary right hide-overlay
            height="270px"
          >
            <v-list-item-content>
              <div class="mx-auto text-center">
                <v-avatar class="mt-3" size="60">
                  <v-img
                    v-if="have_image"
                    :src="image_profile"
                    alt=""
                  />
                  <v-img
                    v-else
                    src="./assets/profile_image.jpg"
                    alt=""
                  />
                </v-avatar>
                <p class="mt-4" style="font-size: 14px"><strong>{{user_data.user}}</strong></p>
                <p class="mt-2" style="font-size: 14px">{{user_data.email}}</p>
                <v-divider class=""></v-divider>
                <v-list dense nav class="text-left">
                  <v-list-item-group color="indigo">
                    <v-list-item to="/edit-account">
                      <v-list-item-icon class="ml-7"><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                      <v-list-item-content><v-list-item-title>แก้ไขโปรไฟล์</v-list-item-title></v-list-item-content>
                    </v-list-item>
                    <v-list-item v-on:click="logoutState()">
                      <v-list-item-icon class="ml-7"><v-icon>mdi-door</v-icon></v-list-item-icon>
                      <v-list-item-content><v-list-item-title>ออกจากระบบ</v-list-item-title></v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-list-item-content>
          </v-navigation-drawer>
        </div>
        <div class="mobile-menu hidden-md-and-up">
          <v-navigation-drawer
            v-model="drawer"
            absolute temporary right
          >
            <div class="mt-5 text-center" v-if="isLogin">
              <v-avatar size="70">
                <v-img
                  v-if="have_image"
                  :src="image_profile"
                  alt=""
                />
                <v-img
                  v-else
                  src="./assets/profile_image.jpg"
                  alt=""
                />
              </v-avatar>
              <p class="mt-4" style="font-size: 14px"><strong>{{user_data.user}}</strong></p>
              <p class="mt-4" style="font-size: 14px">{{user_data.email}}</p>
            </div>
            <div v-else>
              <v-btn class="mt-2 ml-2 mr-2"
              width="240px" dark depressed
              style="background-image: linear-gradient(90deg, #ED8D1D 39%, #FBD76C 80%);"
              to="/login"
              >
                <strong style="font-size: 12px">เข้าสู่ระบบ</strong>
              </v-btn>
            </div>
            <v-list nav dense>
              <v-list-item-group v-if="is_login">
                <div>
                  <v-list-item to="/">
                    <v-list-item-icon class="ml-8"><v-icon>mdi-home</v-icon></v-list-item-icon>
                    <v-list-item-content><v-list-item-title>หน้าหลัก</v-list-item-title></v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="is_admin" to="/admin-petition">
                    <v-list-item-icon class="ml-8"><v-icon>mdi-message-text</v-icon></v-list-item-icon>
                    <v-list-item-content><v-list-item-title>แจ้งปัญหา</v-list-item-title></v-list-item-content>
                  </v-list-item>
                  <v-list-item v-else to="/petition">
                    <v-list-item-icon class="ml-8"><v-icon>mdi-message-text</v-icon></v-list-item-icon>
                    <v-list-item-content><v-list-item-title>แจ้งปัญหา</v-list-item-title></v-list-item-content>
                  </v-list-item>
                  <v-list-item to="/publication">
                    <v-list-item-icon class="ml-8"><v-icon>mdi-heart</v-icon></v-list-item-icon>
                    <v-list-item-content><v-list-item-title>สื่อรณรงค์</v-list-item-title></v-list-item-content>
                  </v-list-item>
                  <v-list-item to="/contact">
                    <v-list-item-icon class="ml-8"><v-icon>mdi-phone</v-icon></v-list-item-icon>
                    <v-list-item-content><v-list-item-title>ติดต่อเรา</v-list-item-title></v-list-item-content>
                  </v-list-item>
                  <v-list-item to="/edit-account">
                    <v-list-item-icon class="ml-8"><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                    <v-list-item-content><v-list-item-title>แก้ไขโปรไฟล์</v-list-item-title></v-list-item-content>
                  </v-list-item>
                  <v-list-item v-on:click="logoutState()">
                    <v-list-item-icon class="ml-8"><v-icon>mdi-door</v-icon></v-list-item-icon>
                    <v-list-item-content><v-list-item-title>ออกจากระบบ</v-list-item-title></v-list-item-content>
                  </v-list-item>
                </div>
              </v-list-item-group>
              <v-list-item-group v-else no-action>
                <div>
                  <v-list-item to="/">
                    <v-list-item-icon class="ml-8"><v-icon>mdi-home</v-icon></v-list-item-icon>
                    <v-list-item-content><v-list-item-title class="ml-6">หน้าหลัก</v-list-item-title></v-list-item-content>
                  </v-list-item>
                  <v-list-item selectable to="/login" >
                    <v-list-item-icon class="ml-8"><v-icon>mdi-message-text</v-icon></v-list-item-icon>
                    <v-list-item-content><v-list-item-title class="ml-6">แจ้งปัญหา</v-list-item-title></v-list-item-content>
                  </v-list-item>
                  <v-list-item selectable href="/login">
                    <v-list-item-icon class="ml-8"><v-icon>mdi-heart</v-icon></v-list-item-icon>
                    <v-list-item-content><v-list-item-title class="ml-6">สื่อรณรงค์</v-list-item-title></v-list-item-content>
                  </v-list-item>
                  <v-list-item to="/contact">
                    <v-list-item-icon class="ml-8"><v-icon>mdi-phone</v-icon></v-list-item-icon>
                    <v-list-item-content><v-list-item-title class="ml-6">ติดต่อเรา</v-list-item-title></v-list-item-content>
                  </v-list-item>
                </div>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
        </div>
        <div class="element">
          <router-view/>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
name: 'App',
data: () => ({
  showMobileMenu: false,
  username:"",
  user_data: {},
  is_login: false,
  url: process.env.VUE_APP_BASE_URL,
  image_profile: null,
  have_image: false,
  hidden: false,
  drawer: false,
  group: null,
  model: 1,
  is_admin: false,
  reload: false,
}),
computed:{
  isLogin: function(){
    console.log(localStorage.getItem('userInfo') !== null)
    return localStorage.getItem('userInfo') !== null
  },
},
mounted(){
  this.user_data = JSON.parse(localStorage.getItem('userInfo'))
  if (this.user_data !== null) {
    this.is_login = true
    if (this.user_data.is_admin===true) {
      this.is_admin = true
    }
  }

  if(this.user_data.image !== null){
    this.have_image = true
    this.image_profile = this.url + this.user_data.image
  }

},
methods:{
  showMenu() {
    this.drawer = !this.drawer
  },
  logoutState(){
    this.is_login = false
    localStorage.clear()
    // window.location.reload()
    // this.$router.push({'name':''})
    window.location.href = "/"
  },
  checkIsLogin(){
    if (isLogin) {
      this.$router.push({'name':'petition'})
    }else{
      this.$router.push({'name':'login'})
    }
  }
},
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@400;600&display=swap');

#app {
  font-family: 'Prompt', sans-serif;
  font-size: calc(13px + 0.290625vw);
  overflow-x: hidden;
  .element{
    @media screen and (max-width: 960px){
      // margin: 1.875rem;
    }
  }
}

.navbar {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  height: 100px;
  .logo{
    margin-left: 2rem;
    width: 5rem;
    height: 5rem;
  }
  .avartar,.login-btn{
    margin-right: 2rem;
  }
}
.navbar-links {
  font-size: 18px;
  margin-top: 50px;
  height: 100%;
  ul {
    display: flex;
    margin: 0;
    padding: 0;
  } 
  li {
    list-style: none;
    a {
      display: block;
      text-decoration: none;
      padding: 1rem;
      margin-left: 30px;
      margin-right: 30px;
    }
  }
}

.navbar-links li:hover {
  background-color: #F6F6F6;
  color: white;
  border-radius: 20px;
}
.toggle-button {
  position: absolute;
  top: .75rem;
  right: 0;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 40px;
  height: 30px;
  hr.bar{
    border: 2px solid #ED8D1D;
    border-radius: 10px;
    background-image: linear-gradient(90deg, #ED8D1D 39%, #FBD76C 80%);
  }
}
@media screen and (max-width: 960px) {
  .navbar{
    height: 80px;
    .toggle-button {
      display: flex;
    }
    .logo{
      margin-left: 2rem;
      width: 4rem;
      height: 4rem;
    }
    .avartar,.login-btn,.toggle-button{
      margin-right: 2rem;
    }
  }
}
</style>
