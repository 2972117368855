import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {Axios_API} from '../api/axiosinterceptor'

const domainName = `${process.env.VUE_APP_URL}/api`;

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    isLogin: false,
    person_id: 1,
    // logged: false,
    // token: '',
    products:[
      {name: 'banana', price: 22},
      {name: 'cupcake', price: 23},
      {name: 'cereal', price: 24},
    ]
  },
  getters: {
    getisLogin: state => state.session.isLogin,
    persenID: function (state) {
      return `${state.person_id}`
    },
    saleProducts: state => {
      let saleProducts = state.products.map( product => {
        return {
          name: '**' + product.name,
          price: product.price/2
        }
      });
      return saleProducts;
    } 
  },
  mutations: {
    SET_USER(state, payload) {
      // const splitName = payload.user.split("-");
      let initials = "";
      // if (splitName.length > 1) {
      //   const firstname = splitName[0].charAt(0).toUpperCase();
      //   const lastname = splitName[1].charAt(0).toUpperCase();
      //   initials = firstname + lastname;
      // } else {
      //   initials = splitName[0].charAt(0).toUpperCase();
      // }
      state.user = {
        initials: initials,
        fullName: payload.user,
        email: payload.email,
      };
    },
    SET_LOGIN(state) {
      state.isLogin = true;
    },
    SET_LOGOUT(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      state.isLogin = false;
    },
    getUserProfile({ commit }) {
      Axios_API.get(`/v2/account/me/`).then((res) => {
        commit("SET_USER", res.data);
      });
    },
    reducePrice: (state,payload) => {
      // setTimeout(() => {
        state.products.forEach(product => {
          product.price -= payload;
        })
      // }, 3000);
    }
  },
  actions: {
    reducePrice: (context,payload) => {
      setTimeout(() => {
        context.commit('reducePrice',payload)
      }, 2000);
    }
  },
  modules: {
  }
})
